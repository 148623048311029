  .projects-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .projects-title {
    color: rgba(9, 50, 108, 1);
    text-align: center;
    font: 700 32px Inter, sans-serif;
    margin: 100px 0px;
  }