.logo {
    display: flex;
    color: #afcef8;
    letter-spacing: 3.9px;
    font: 800 26px THICCCBOI, sans-serif;
  }
  .logo-image {
    aspect-ratio: 1.25;
    object-fit: contain;
    width: 125px;
    border-radius: 5px;
    max-width: 100%;
  }
  .logo-text {
    margin-top: 20px;
    text-align: left;
  }
  @media (max-width: 768px) {
    .logo {
      white-space: normal;
    }
  }