.social-icons {
    display: flex;
    margin-top: 15px;
    gap: 12px;
  }
  .social-icon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 30px;
  }
  .social-icon:hover{
    color: #afcef8;
    cursor: pointer;
  }