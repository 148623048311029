.presentation-description {
    font-size: 26px;
    margin-top: 35px;
  }
  @media (max-width: 768px) {
    .presentation-description {
      max-width: 100%;
      margin-top: 40px;
      font-size: 22px;
    }
  }