.contact-section {
    background-color: rgba(175, 206, 248, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 93px 80px;
    margin-top: 100px;
    font: 400 24px Inter, sans-serif;
  }
  .contact-header {
    margin-bottom: 0px;
  }
  .contact-title {
    display: flex;
    text-transform: uppercase;
    gap: 11px;
    font-size: 36px;
    font-weight: 700;
  }
  .contact-title-main {
    color: #000;
  }
  .contact-title-accent {
    color: #09326c;
  }
  .contact-description {
    color: #000;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 50px;
  }
  .contact-form {
    width: 100%;
    max-width: 1050px;
  }
  .form-row {
    display: flex;
    margin-bottom: 47px;
    justify-content: space-between;
    width: 100%;
  }
  @media (min-width: 769px) {
    .contact-title {
      flex-direction: row;
    }
  }

  @media (max-width: 768px) {
    .contact-title {
      flex-direction: column;
    }
    .contact-section {
      padding: 0 20px 100px;
    }
    .contact-description {
      margin-bottom: 40px;
    }
    .form-row {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;
    }
  }