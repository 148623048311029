.not-found-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 20px;
    padding: 30px;
}

.not-found-icon {
    height: 200px;
    object-fit: contain;
    margin: 0 auto;
    @media (max-width: 768px) {
        height: 150px;
    }
}

.not-found-title {
    font-size: 65px;
    font-weight: 700;
    color: #09326c;
    margin: 0 auto;
    @media (max-width: 768px) {
        font-size: 55px;
    }
}

.not-found-subtitle {
    font-size: 30px;
    font-weight: 500;
    @media (max-width: 768px) {
        font-size: 24px;
    }

}

.not-found-button {
    border-radius: 5px;
    background-color: #09326c;
    width: auto;
    font-size: 18px;
    padding: 15px 25px;
    color: #fff;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.not-found-button:hover {
  background-color: #0a4085;
}