.aboutUsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 20px;
  }
  
  .about-us-title {
    text-align: center;
    color: #09326c;
    font-size: 32px;
    font-weight: 700;
    margin: 0 auto;
  }
  
  .subtitle {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
  
  .missionSection {
    background-color: rgba(175, 206, 248, 0.25);
    width: 100%;
  }
  
  .missionContent {
    display: flex;
  }
  
  .imageWrapper {
    flex: 0 0 35%;
  }
  
  .missionImage {
    aspect-ratio: 0.98;
    object-fit: contain;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .textContent {
    flex: 0 0 57%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .missionText {
    color: #09326c;
    font-size: 22px;
  }
  
  .learnMoreButton {
    border-radius: 5px;
    background-color: #09326c;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    align-self: center;
    width: 200px;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    padding: 15px 25px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .learnMoreButton:hover {
    background-color: #0a4085;
  }
  @media (min-width: 769px) {
    .missionText {
      text-align: left;
    }
    .missionContent {
      gap: 50px;
      padding: 0px 50px;
    }
  }
  
  @media (max-width: 768px) {
    .subtitle {
      font-size: 40px;
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .missionSection {
      margin-top: 40px;
    }
  
    .missionContent {
      flex-direction: column;
      padding: 25px 50px;
    }
    .missionText {
      text-align: center;
    }
  
    .imageWrapper,
    .textContent {
      flex: 1 1 100%;
    }
  
    .missionImage {
      margin-top: 21px;
    }
  
    .textContent {
      margin-top: 40px;
    }
  
    .learnMoreButton {
      padding: 21px 20px;
    }
  }