
  .article-card-container {
    background-color: #fff;
    display: flex;
    width: 300px;
    max-width: 100%;
    flex-direction: column;
    color: #000;
    padding: 8px 14px 20px;
    font: 700 16px Inter, sans-serif;
    @media (max-width: 768px) {
      margin-left: 10px;
      margin: 0 auto;
    }
  }

  .article-card {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: #000;
    width: 100%;
    margin-top: 15px;
    font: 700 16px Inter, sans-serif;
    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  .article-date {
    font-weight: 300;
    align-self: start;
  }

  .article-title {
    font-size: 26px;
    margin: 16px 20px 0 0;
    text-align: left;
    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }

  .article-excerpt {
    font-weight: 500;
    margin-top: 19px;
    text-align: left;
    height: 64px;
    line-height: 16px;
    word-wrap: break-word;
    text-overflow: ellipsis; 
    overflow: hidden;
    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }
  
  .article-excerpt:after { content: "..."; position :absolute; right: 0; bottom: 2px;}
  
  .side-image-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 350px;
    margin-left: 0px;
  }

  .side-article-image {
    aspect-ratio: 1.5;
    object-fit: contain;
    object-position: center;
    width: 300px;
    border-radius: 5px 5px 0px 5px;
    flex-grow: 1;
    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  .learn-more-btn {
    border-radius: 5px;
    background-color: #09326c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    align-self: end;
    margin-top: 22px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 11px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .learn-more-btn:hover {
    background-color: #0a4085;
  }
