  .nav-links {
    gap: 40px;
  }

  .nav-link {
    flex-grow: 1;
    text-decoration: none;
    color: inherit;
  }

  .nav-link:hover{
    color: #afcef8;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .nav-links {
      display: flex;
      flex-direction: column;
    }
    .nav-link {
      margin-left: auto;
      margin-right: 0;
      font-size: large;
    }
  }

  @media (min-width: 769px) {
    .nav-links {
      display: flex;
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto; 
      margin-right: 0;
    }
    .nav-link {
      margin: 0 auto;
    }
  }