.dream-section {
    display: flex;
    flex-direction: column;
    height: 90vh;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }
  .content-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    top: 25%;
    left: 0%;
    bottom: 25%;
    right: 0%;
  }
  @media (max-width: 768px) {
    .dream-section {
      padding: 0;
    }
    .content-wrapper {
      padding: 0 20px;
    }
  }