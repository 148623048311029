.footer {
    background-color: #09326c;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    border: 1px solid #000;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .logo-section {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .links-section {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .links-container{
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
  }
  .copyright {
    font-size: 16px;
    font-weight: 400;
    margin-top: 82px;
    color: #fff;
  }
  .social-section {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .follow-us {
    color: #fff;
    text-align: center;
    font: 400 16px Inter, sans-serif;
  }
  .legal-links {
    display: flex;
    margin-top: 81px;
    font-size: 16px;
    font-weight: 400;
    justify-content: space-evenly;
  }
  .legal-link {
    color: #fff;
    text-decoration: none;
  }
  .separator {
    color: #fff;
  }
  @media (min-width: 769px) {
    .footer {
      padding: 51px 70px;
    }
    .footer-content {
      width: 100%;
      max-width: 1177px;
      gap: 20px;
    }
    .links-container{
      flex-direction: row;
    }
    .copyright {
      width: 700px;
    }
    .legal-links{
      width: 700px;
    }
    .follow-us{
      margin: 52px 0 0 20px;
    }
  }

  @media (max-width: 768px) {
    .logo-section{
      margin-top: 20px;
    }
    .footer {
      padding: 30px 20px;
    }
    .footer-content {
      max-width: 100%;
    }
    .copyright, .contact-section, .legal-links {
      margin-top: 40px;
    }
    .copyright {
      width: max-content;
    }
    .legal-links{
      width: max-content;
      margin-left: auto;
      margin-right: auto;
      gap: 10px;
    }
    .links-container {
      flex-direction: column-reverse;
    }
    .follow-us{
      margin-bottom: 0px;
    }
    .social-section{
      margin-top: 20px;
    }
  }