  .news-articles-section {
    display: flex;
    flex-direction: column;
  }

  .news-section-title {
    color: #000;
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    margin-top: -11px;
    font: 700 48px Inter, sans-serif;
    @media (max-width: 768px) {
      max-width: 100%;
      font-size: 40px;
    }
  }

  .articles-container {
    margin-top: 50px;
    width: 100%;
    @media (max-width: 768px) {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .articles-row {
    gap: 20px;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }

  .main-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 65%;
    margin-left: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .main-article-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    @media (max-width: 768px) {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .image-gallery {
    z-index: 10;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .article-image {
    aspect-ratio: 1.5;
    object-fit: contain;
    object-position: center;
    width: 100%;
    border-radius: 5px 5px 0px 5px;
  }

  .articles-grid {
    align-self: end;
    margin-top: -32px;
    width: 703px;
    max-width: 100%;
  }

  .articles-row {
    gap: 20px;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
    }
  }

  .article-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .side-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 100%;
    @media (min-width: 769px){
      margin-left: 20px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .side-article-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    @media (max-width: 768px) {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .side-image-gallery {
    z-index: 10;
    @media (max-width: 768px) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .side-image-row {
    gap: 20px;
    display: flex;
    width: 90%;
    margin: 0 auto;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      gap: 0px;
      margin: 0 auto;
    }
  }

  .side-icon-column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 17%;
    margin-left: 20px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .side-icon:hover{
    cursor: pointer;
    background-color: lightgray;
    transition: background-color 0.3s ease;
  }

  .icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 70px;
    margin: auto 0;
  }

  .side-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 70px;
    align-self: stretch;
    margin: auto 0;
    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }