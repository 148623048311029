  .service-card {
    background-color: #09326c;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    text-align: center;
  }
  .service-icon {
    width: 65px;
    height: 65px;
    object-fit: contain;
  }
  .service-title {
    font-size: 20px;
    font-weight: 700;
    margin: 14px 0;
    width: 200px;
  }
  .service-description {
    font-size: 16px;
    font-weight: 500;
    width: 200px;
  }