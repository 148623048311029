.logo-container {
    display: flex;
    gap: 10px;
    font-family: THICCCBOI, sans-serif;
    color: #afcef8;
    font-weight: 800;
    white-space: nowrap;
    letter-spacing: 3px;
  }
  @media (max-width: 768px) {
    .logo-container {
      white-space: initial;
    }
    .header-logo-text{
      display: none;
      text-decoration: none;
    }
  }

  .logo-container:hover{
    cursor: pointer;
  }
  .header-logo-image {
    aspect-ratio: 1.15;
    object-fit: contain;
    object-position: center;
    width: 50px;
  }
  .header-logo-text {
    width: auto;
    margin: auto 0;
    text-align: left;
    text-decoration: none;
  }