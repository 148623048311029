.navbar {
    background-color: #09326c;
    display: flex;
    align-items: left;
    color: #fff;
    padding: 15px 30px 15px 16px;
    font: 700 16px Inter, sans-serif;
    position: sticky;
    top: 0px;
    z-index: 99;
  }

  .icons-container {
    display: flex;
  }
  @media (max-width: 768px) {
    .navbar {
      padding-right: 20px;
      flex-direction: column;
    }
    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }
    .is-open {
      border-top: 1px solid #fff;
      margin-top: 20px;
      padding-top: 10px;
    }
  }
  @media (min-width: 769px) {
    .mobile {
      display: none;
    }
    
    .desktop {
      display: flex;
    }

    .navbar {
      gap: 40px 66px;
    }

    .navbar-actions-container{
      gap: 40px;
    }
    .is-open {
      display: flex;
      gap: 40px;
      align-items: right;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto; 
      margin-right: 0;
    }
  }
  

  .navbar-actions-container{
    align-items: right;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto; 
    margin-right: 0;
  }