.submit-button {
    border-radius: 5px;
    background-color: #09326c;
    margin-top: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 219px;
    max-width: 100%;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    padding: 14px 0;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .submit-button:hover {
    background-color: #0a4085;
  }
  .submit-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(9, 50, 108, 0.5);
  }